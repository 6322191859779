import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api'
import React, { useRef, useState } from 'react'

import AppHeader from '../../../../components/header'
import Input from 'react-phone-number-input/input'
import LoadingSpinner from '../../../../components/loading'
import axios from 'axios'
import logo from '../../../../assets/logo.png'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const libraries = ['places']

const CreateReference = () => {
	const navigate = useNavigate()
	const inputRef = useRef()
	const [name, setName] = useState('')
	const [lastname, setLastname] = useState('')
	const [email, setEmail] = useState('')
	const [address, setAddress] = useState('')
	const [phone, setPhone] = useState('')
	const [fullAddress, setFullAddress] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const handlePlaceChanged = () => {
		const [place] = inputRef.current.getPlaces()
		if (place) {
			const { formatted_address } = place
			setFullAddress(formatted_address)
			setAddress({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
			})
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		setIsLoading(true)
		const URL = `${process.env.REACT_APP_API_URL}/reference`
		axios
			.post(URL, {
				name,
				last_name: lastname,
				email,
				address,
				phone,
				full_address: fullAddress,
			})
			.then((res) => {
				setIsLoading(false)
				toast.success('Felicidades, el registro ha sido exitoso', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				})
				navigate('/admin/referentes')
			})
			.catch((err) => {
				console.log(err)
				setIsLoading(false)
				toast.error('Error, por favor intente nuevamente', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				})
			})
	}

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<>
			<AppHeader />
			<div className="flex w-25 ml-2">
				<button
					onClick={() => navigate(-1)}
					className="flex w-25 my-2 justify-center rounded-full bg-blue-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
				>
					Volver
				</button>
			</div>
			<div className="flex min-h-full flex-1 flex-col justify-center py-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-xl">
					<div className="rounded-2xl shadow-2xl p-6 border border-gray-100 bg-gray-100">
						<div className="w-full flex justify-center">
							<img src={logo} className="h-36 self-center " alt="" />
						</div>
						<form className="space-y-6" onSubmit={handleSubmit}>
							<div>
								<label
									htmlFor="name"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Nombre
								</label>
								<div className="mt-2">
									<input
										id="name"
										name="name"
										type="text"
										autoComplete="name"
										required
										value={name}
										onChange={(e) => setName(e.target.value)}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>

							<div>
								<div className="flex items-center justify-between">
									<label
										htmlFor="lastname"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Apellido
									</label>
								</div>
								<div className="mt-2">
									<input
										id="lastname"
										name="lastname"
										type="text"
										autoComplete="lastname"
										value={lastname}
										onChange={(e) => setLastname(e.target.value)}
										required
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>

							<div>
								<div className="flex items-center justify-between">
									<label
										htmlFor="password"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Dirección
									</label>
								</div>
								<div className="mt-2">
									<input type="hidden" name="fullAddress" value={fullAddress} />
									<LoadScript
										googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
										libraries={libraries}
									>
										<StandaloneSearchBox
											onLoad={(ref) => (inputRef.current = ref)}
											onPlacesChanged={handlePlaceChanged}
										>
											<input
												type="text"
												required
												placeholder="Ej: Calle 1, Barrio, Ciudad"
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
											/>
										</StandaloneSearchBox>
									</LoadScript>
								</div>
							</div>

							<div>
								<div className="flex items-center justify-between">
									<label
										htmlFor="email"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Correo Electronico
									</label>
								</div>
								<div className="mt-2">
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										required
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>

							<div>
								<div className="flex items-center justify-between">
									<label
										htmlFor="phone"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Telefono celular
									</label>
								</div>
								<div className="mt-2">
									<Input
										id="phone"
										name="phone"
										placeholder="Enter phone number"
										country="AR"
										international
										withCountryCallingCode
										value={phone}
										onChange={setPhone}
										required
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>

							<div>
								<button
									type="submit"
									className="flex w-full justify-center rounded-md bg-blue-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Crear Referente
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreateReference
