import './index.css'

import React, { useState } from 'react'

import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import Routes from './routes/index'
import { ToastContainer } from 'react-toastify'
import UserContext from './context/UserContext'
import { store } from './redux/store'

const App = () => {
	const [user, setUser] = useState(false)

	return (
		<UserContext.Provider value={{ user, setUser }}>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<ToastContainer />
			<Provider store={store}>
				<RouterProvider router={Routes} />
			</Provider>
		</UserContext.Provider>
	)
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)
