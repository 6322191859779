import { Dialog, Transition } from '@headlessui/react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import React, { Fragment, useEffect, useState } from 'react'

import AppHeader from '../../../../components/header'
import LoadingSpinner from '../../../../components/loading'

const ListActivistMap = () => {
	const [locations, setLocations] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [selected, setSelected] = useState('')
	const center = { lng: -58.61975, lat: -34.65344 }

	const zoom = 12

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	})

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/activist/all`)
			.then((res) => res.json())
			.then((res) => {
				console.log(res)
				setLocations(res['activists'])
			})
	}, [])

	const handlerOnClick = (i) => {
		setIsOpen(!isOpen)
		setSelected(i)
	}

	if (!isLoaded) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<>
			<AppHeader />
			<div
				style={{ backgroundColor: '#f4f4f4' }}
				className="flex flex-col self-center justify-between w-48 h-14 m-auto my-4 border border-gray-300 rounded-xl shadow-lg p-4"
			>
				<h3>
					Total Afiliados:{' '}
					<span className="font-semibold">{locations.length}</span>
				</h3>
			</div>
			<div
				style={{
					height: 'calc(100vh - 190px)',
					width: 'calc(100vw - 100px)',
					margin: '0 auto',
				}}
			>
				<GoogleMap
					mapContainerStyle={{
						width: '100%',
						height: '100%',
						position: 'relative',
					}}
					center={center}
					zoom={zoom}
					options={{
						zoomControl: true,
						streetViewControl: false,
						mapTypeControl: false,
					}}
				>
					{locations.map((location, i) => (
						<Marker
							key={i}
							position={location['direction']}
							label={i.toString()}
							onClick={() => {
								handlerOnClick(i)
								console.log('click')
							}}
						/>
					))}
				</GoogleMap>
			</div>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={handlerOnClick}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title
										as="h3"
										className="text-lg font-medium leading-6 text-gray-900"
									>
										Detalle de Afiliado
									</Dialog.Title>
									<div className="mt-2">
										<p className="text-sm text-gray-500">
											Nombre:{' '}
											{locations[selected]
												? locations[selected]['name'] +
												  ' ' +
												  locations[selected]['last_name']
												: 'Sin información'}
										</p>
										<p className="text-sm text-gray-500">
											Dirección:{' '}
											{locations[selected]
												? locations[selected]['full_address']
												: 'Sin información'}
										</p>
									</div>

									<div className="mt-4">
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
											onClick={handlerOnClick}
										>
											Volver
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ListActivistMap
