import Cookies from 'universal-cookie'
import { createSlice } from '@reduxjs/toolkit'

const cookies = new Cookies()

const initialState = {
	user: cookies.get('user') ?? null,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload
		},
		deleteUser: (state) => {
			state.user = null
		},
	},
})

export const { setUser, deleteUser } = userSlice.actions

export default userSlice.reducer
