import CreateReference from '../pages/reference/create'
import IndexReference from '../pages/reference'
import ListReference from '../pages/reference/list'
import ShowReference from '../pages/reference/show'

const ReferenceRoutes = {
	path: '/',
	children: [
		{
			path: '/',
			children: [
				{
					path: 'reference/',
					element: <IndexReference />,
				},
				{
					path: 'reference/create',
					element: <CreateReference />,
				},
				{
					path: 'reference/list',
					element: <ListReference />,
				},
				{
					path: 'reference/:id',
					element: <ShowReference />,
				},
			],
		},
	],
}

export default ReferenceRoutes
