import React, { useEffect, useState } from 'react'

import AppHeader from '../../components/header'
import LoadingSpinner from '../../components/loading'
import Table from '../../components/table'

// import { useSelector } from 'react-redux'

const IndexReference = () => {
	const [data, setData] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	// const user = useSelector((state) => state.user.user)

	useEffect(() => {
		const URL = `${process.env.REACT_APP_API_URL}/reference`
		setIsLoading(true)
		fetch(URL)
			.then((response) => response.json())
			.then((data) => {
				const { references } = data
				setData(references)
				setIsLoading(false)
			})
	}, [])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	// if (user === null || !user['is_admin']) {
	// 	return (
	// 		<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
	// 			<h1>No tienes permisos para acceder a esta página</h1>
	// 		</div>
	// 	)
	// }

	return (
		<>
			<AppHeader />
			<div className="m-auto flex justify-center align-center">
				<Table data={data} />
			</div>
		</>
	)
}

export default IndexReference
