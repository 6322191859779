import { useEffect, useState } from 'react'

import Cookies from 'universal-cookie'
import LoadingSpinner from '../../components/loading'
import axios from 'axios'
import logo_completo from '../../assets/logo_completo.png'
import { setUser } from '../../redux/userSlice'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const IndexLogin = () => {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [passwordType, setPasswordType] = useState('password')
	const dispatch = useDispatch()

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text')
			return
		}
		setPasswordType('password')
	}

	const handlerOnClick = (event) => {
		event.preventDefault()
		setIsLoading(true)
		const URL = `${process.env.REACT_APP_API_URL}/login`
		axios
			.post(URL, {
				email,
				password,
			})
			.then((response) => {
				setIsLoading(false)
				if (response['data']['first_login'] === true) {
					navigate('/first-login', { state: { email } })
				} else {
					dispatch(setUser(response['data']['user']))
					const cookies = new Cookies()
					cookies.set('user', response['data']['user'], {
						path: '/',
						expires: new Date(Date.now() + 604800000),
					})
					if (response['data']['is_admin'] === true) {
						navigate('/admin')
					}
				}
			})
			.catch((error) => {
				console.log(error)
				setIsLoading(false)
				toast.error('Error, por favor intente nuevamente', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				})
			})
	}

	useEffect(() => {
		const keyDownHandler = (event) => {
			if (event.key === 'Enter') {
				document.getElementById('email').blur()
				document.getElementById('password').blur()

				document.getElementById('login-button').click()
			}
		}

		document.addEventListener('keydown', keyDownHandler)

		return () => {
			document.removeEventListener('keydown', keyDownHandler)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<div className="min-h-screen flex flex-col justify-center z-10">
			<div className="relative w-3/4 sm:max-w-xl sm:mx-auto">
				<div
					className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-blue-300 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"
					style={{ opacity: '0.6' }}
				></div>
				<div className="relative px-4 py-10 bg-opacity-50 bg-white shadow-lg sm:rounded-3xl sm:p-16">
					<div className="max-w-md mx-auto flex justify-center flex-col ">
						<div className="my-4 self-center">
							<img alt="" src={logo_completo} width={350} />
						</div>
						{/* <form
							className="mt-8 space-y-6"
							action={`${process.env.REACT_APP_API_URL}/login`}
							method="POST"
						> */}
						<div className="divide-y divide-gray-200">
							<div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
								<div>
									<label
										htmlFor="email"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Correo Electrónico
									</label>

									<div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden my-2">
										<input
											className="peer h-full w-full outline-none border-0 text-sm text-gray-600 pr-2 focus:border-0 focus:ring-0"
											id="email"
											name="email"
											type="email"
											value={email}
											onChange={(event) => setEmail(event.target.value)}
											required
										/>
									</div>
								</div>

								<div>
									<label
										htmlFor="password"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Contraseña
									</label>
									<div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden my-2">
										<input
											className="peer h-full w-full outline-none border-0 text-sm text-gray-600 pr-2 focus:border-0 focus:ring-0"
											id="password"
											name="password"
											value={password}
											type={passwordType}
											onChange={(event) => setPassword(event.target.value)}
											required
										/>
										<div className="grid place-items-center h-full w-12 text-gray-200">
											<button
												className="btn btn-outline-primary"
												onClick={togglePassword}
											>
												{passwordType === 'password' ? (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														stroke-width="1.5"
														stroke="grey"
														className="w-6 h-6"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
														/>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
														/>
													</svg>
												) : (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														stroke-width="1.5"
														stroke="grey"
														className="w-6 h-6"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
														/>
													</svg>
												)}
											</button>
										</div>
									</div>
								</div>

								<button
									onClick={handlerOnClick}
									type="button"
									disabled={isLoading}
									id="login-button"
									className="flex w-full justify-center rounded-md bg-blue-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Ingresar
								</button>
							</div>
						</div>
						{/* </form> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default IndexLogin
