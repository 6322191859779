import CreateReference from '../pages/admin/reference/create'
import IndexAdmin from '../pages/admin'
import IndexReference from '../pages/admin/reference'
import ListActivistMap from '../pages/admin/activist/listMap'
import ListDetailReference from '../pages/admin/reference/list/'

const AdminRoutes = {
	path: '/',
	children: [
		{
			path: '/',
			children: [
				{
					path: 'admin/',
					element: <IndexAdmin />,
				},
				{
					path: 'admin/referentes',
					element: <IndexReference />,
				},
				{
					path: 'admin/referentes/crear',
					element: <CreateReference />,
				},
				{
					path: 'admin/referentes/:id',
					element: <ListDetailReference />,
				},
				{
					path: 'admin/afiliados',
					element: <ListActivistMap />,
				},
			],
		},
	],
}

export default AdminRoutes
