import ActivistRoutes from './activistRoutes'
import AdminRoutes from './adminRoutes'
import FirstLogin from '../pages/login/firstLogin'
import Index from '../pages'
import IndexLogin from '../pages/login'
import ReferenceRoutes from './referenceRoutes'
import { createBrowserRouter } from 'react-router-dom'

const Routes = createBrowserRouter([
	{
		path: '/login',
		element: <IndexLogin />,
	},
	{
		path: '/first-login',
		element: <FirstLogin />,
	},
	{
		path: '/',
		element: <Index />,
	},
	{
		path: '/',
		children: [ActivistRoutes, ReferenceRoutes, AdminRoutes],
	},
])

export default Routes
