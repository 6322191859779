import CreateActivist from '../pages/activist/create'
import IndexActivist from '../pages/activist'

const ActivistRoutes = {
	path: '/',
	children: [
		{
			path: '/',
			children: [
				{
					path: 'activist/:referent?',
					element: <IndexActivist />,
				},
				{
					path: 'activist/success',
					element: <CreateActivist />,
				},
			],
		},
	],
}

export default ActivistRoutes
